import { TimeConverterInputFieldTypeEnum } from "./types/TimeConverterInputField";

export function convertToHours(value: number | string, unit: keyof typeof TimeConverterInputFieldTypeEnum): number {

    const numericValue: number = typeof value === 'string' ? parseFloat(value) : value;

    if (isNaN(numericValue) || numericValue <= 0) {
        return 0
    }

    switch (unit) {
        case TimeConverterInputFieldTypeEnum.Years:
            return numericValue * 365 * 24; // Assuming a year has 365 days
        case TimeConverterInputFieldTypeEnum.Months:
            return numericValue * 30 * 24; // Assuming a month has 30 days
        case TimeConverterInputFieldTypeEnum.Weeks:
            return numericValue * 24 * 7;
        case TimeConverterInputFieldTypeEnum.Days:
            return numericValue * 24;
        case TimeConverterInputFieldTypeEnum.Hours:
            return numericValue;
        default:
            return 0
    }
}

export function convertFromHours(hours: number): { value: number; unit: keyof typeof TimeConverterInputFieldTypeEnum } {
    if (hours % (24 * 365) === 0) {
        const years = hours / (24 * 365);
        return { value: years, unit: TimeConverterInputFieldTypeEnum.Years };
    } else if (hours % (24 * 30) === 0) {
        const months = hours / (24 * 30);
        return { value: months, unit: TimeConverterInputFieldTypeEnum.Months };
    } else if (hours % (24 * 7) === 0) {
        const weeks = hours / (24 * 7);
        return { value: weeks, unit: TimeConverterInputFieldTypeEnum.Weeks };
    } else if (hours % 24 === 0) {
        const days = hours / 24;
        return { value: days, unit: TimeConverterInputFieldTypeEnum.Days };
    }

    return { value: hours, unit: TimeConverterInputFieldTypeEnum.Hours };
}